import { Flex, Modal, Table, Tag, Tooltip } from 'antd';
import { addPatient } from 'api/firebase';
import { Patient } from 'documents/patient';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { useEffect, useMemo, useState } from 'react';
import { getFirstName, getLastName } from 'utils/string/normalise';
import { z } from 'zod';
import UserAddModal from './user-add-modal-component';

import { Title } from 'components/mvp-typography';
import { uniqBy } from 'lodash';
import {
  ActionTag,
  ActionTagErrorContextProvider,
  ActionTagErrorDisplay,
  ActionTagSettings
} from './action-tag-component';
import { CrxSearch } from '../../../components/search-component/search-component';

const tableUserDataSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email()
});

export type InData = Pick<Patient, 'displayName' | 'email' | 'id'>;
type TableUserData = z.infer<typeof tableUserDataSchema>;
export type TableRow<T> = TableUserData & {
  invitePending: boolean;
  key: string;
  raw: T;
};

interface ListUserReturnResult<T extends InData> {
  inClinic: T[];
  pendingInClinic: T[];
}

interface UserListProps<T extends InData> {
  clinicId: string;
  api: (options: {
    clinicId: string;
    lastUserId?: string;
    lastPendingUserId?: string;
  }) => Promise<{ data: ListUserReturnResult<T> }>;
  actions?: ActionTagSettings<T>[];
  typeUnit: string;
}

function processAPI<T extends InData>(
  data: ListUserReturnResult<T>
): TableRow<T>[] {
  return [
    ...data.inClinic.map(_ => ({ ..._, invitePending: false })),
    ...data.pendingInClinic.map(_ => ({
      ..._,
      invitePending: true
    }))
  ].map(_ => ({
    id: _.id as string,
    key: _.email,
    firstName: getFirstName(_.displayName),
    lastName: getLastName(_.displayName),
    email: _.email,
    invitePending: _.invitePending,
    raw: _
  }));
}

export default function UserList<T extends InData>(props: UserListProps<T>) {
  const [data, setData] = useState<{
    data: TableRow<T>[];
    isLoading: boolean;
  }>({
    data: [],
    isLoading: true
  });
  const [error, setError] = useState<Error | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  async function loadData(refreshData = false) {
    try {
      let apiResult = await props.api({ clinicId: props.clinicId });

      while (
        apiResult.data.inClinic.length !== 0 ||
        apiResult.data.pendingInClinic.length !== 0
      ) {
        const processedData = processAPI(apiResult.data);

        setData(oldState => {
          let tableData = oldState.data;

          /**
           * Refresh data by replacing old records with new records
           */
          if (refreshData) {
            const resultMap: Record<string, TableRow<T>> = processedData.reduce(
              (acc, cur) => ({ ...acc, [cur.key]: cur }),
              {}
            );
            tableData = oldState.data.map(_ => {
              const newRecord = resultMap[_.key];

              if (
                newRecord &&
                // Replace the record if the record changed
                JSON.stringify(_) !== JSON.stringify(newRecord)
              ) {
                return newRecord;
              }

              return _;
            });
          }
          return {
            isLoading: false,
            data: uniqBy([...tableData, ...processedData], _ => _.key)
          };
        });

        // TODO: Reduce re-renders when one of the arrays goes to 0 before the other one
        apiResult = await props.api({
          clinicId: props.clinicId,
          lastUserId:
            apiResult.data.inClinic[apiResult.data.inClinic.length - 1]?.id,
          lastPendingUserId:
            apiResult.data.pendingInClinic[
              apiResult.data.pendingInClinic.length - 1
            ]?.id
        });

        setError(null);
      }
    } catch (e) {
      console.error(`Failed to fetch ${props.api.name} API`);
      console.error(e);

      if (e instanceof Error) {
        setError(e);
      } else if (typeof e === 'string') {
        setError(new Error(e));
      } else {
        setError(new Error(`Unknown error. Got ${e}`));
      }

      // Stop loading when there is an error
      setData(_ => ({ ..._, isLoading: false }));
    }
  }

  useEffect(
    () => {
      loadData();
    },
    // eslint-disable-next-line
    [props.api, props.clinicId]
  );

  const filteredData = useMemo(() => {
    if (searchQuery === null) return data.data;
    return data.data.filter(record =>
      JSON.stringify({
        name: [record.firstName, record.lastName].join(' '),
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email
      })
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  }, [data.data, searchQuery]);

  return (
    <ActionTagErrorContextProvider>
      <Flex justify={'space-between'}>
        <Title>{props.typeUnit}s</Title>
        <div>
          <CrxSearch
            onSearch={val => {
              if (val === '') {
                setSearchQuery(null);
                return;
              }
              setSearchQuery(val);
            }}
            placeholder={[
              'Search',
              props.typeUnit ? props.typeUnit + 's' : null
            ]
              .filter(_ => !!_)
              .join(' ')}
          />
        </div>
      </Flex>

      <Table
        loading={data.isLoading}
        dataSource={filteredData}
        columns={[
          {
            title: 'Full Name',
            dataIndex: 'firstName',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            render: (_, r) => [r.firstName, r.lastName].join(' ')
          },
          {
            title: 'Email Address',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email)
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_value, record) => {
              const actions = props.actions ?? [];

              const tags = actions.map((action, index) => {
                if (
                  action.shouldShow === false ||
                  (typeof action.shouldShow === 'function' &&
                    !action.shouldShow(record))
                ) {
                  return <></>;
                }

                return (
                  <ActionTag
                    key={`action-${index}-${action.name}`}
                    action={{
                      ...action,
                      onClick: async a => {
                        await action.onClick(a);
                        await loadData(true);
                      }
                    }}
                    record={record}
                  />
                );
              });

              return tags;
            }
          },
          {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => +a.invitePending - +b.invitePending,
            render(_value, record) {
              return (
                <Tooltip
                  title={
                    record.invitePending
                      ? 'The user has been invited to the clinic but has not accepted their invitation'
                      : 'The user has accepted their invitation'
                  }
                >
                  <Tag
                    color={record.invitePending ? 'geekblue' : 'green'}
                    style={{ padding: '0.1rem' }}
                  >
                    {record.invitePending ? 'Invite pending' : 'Accepted'}
                  </Tag>
                </Tooltip>
              );
            }
          }
        ]}
      />

      <UserAddModal
        onAddUser={async userData => {
          await addPatient({
            ...tableUserDataSchema.parse(userData),
            clinicId: props.clinicId
          });

          await loadData(true);
        }}
        type="patient"
      />

      <Modal title="Test"></Modal>
      <ActionTagErrorDisplay />
      {error && (
        <ErrorDisplay
          title={`Something went wrong when loading data`}
          description={
            'If you think this is an error please send an error report to contact@concussionrx.com'
          }
          error={error}
        />
      )}
    </ActionTagErrorContextProvider>
  );
}
