import { ConfigProvider, Flex, Table } from 'antd';
import {
  adminQueryPatientAssessmentStatus,
  db
} from 'api/firebase/firebase-api';
import { Text, Title } from 'components/mvp-typography';
import { PrintableRootPage } from 'components/page/page-2';
import { fetchPatientData } from 'components/patient-info/patientInfoSlice';
import { PatientRoutePattern } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { CrxSearch } from 'components/search-component/search-component';
import { Collection } from 'documents/document';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { doesUserHaveAssessments } from 'utils/user-has-assessments';
import './clinician-home-v2.scss';

/**
 * Copied from backend
 */
interface PatientInfo {
  patientId: string;
  displayName: string;
  email: string;
  signUpDate: moment.Moment;
  /**
   * Includes pending assessments
   */
  recentAssessmentDate: moment.Moment | null;
  /**
   * The last confirmed assesment
   */
  lastCompletedAssessment: moment.Moment | null;
}

type APIPatientInfo = PatientInfo & {
  lastCompletedAssessment: string | null;
  recentAssessmentDate: string | null;
  signUpDate: string;
};

export default function ClinicianHomeV2() {
  const [data, setData] = useState<{ data: PatientInfo[]; isLoading: boolean }>(
    { data: [], isLoading: true }
  );
  const [state_error, state_setError] = useState<Error | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const history = useHistory();

  async function onFinish(args: { patientEmail: string }) {
    try {
      state_setError(null);
      // Don't do anything if the input is empty
      if (args.patientEmail.length === 0) return;

      const result = await db
        .collection(Collection.Patients)
        .where('email', '==', args.patientEmail)
        .get();

      // Creates a list of ids
      const ids = result.docs.map(_ => _.id);
      if (ids.length === 0) {
        throw new Error(`No user with (${args.patientEmail}) found.`);
      }

      if (ids.length !== 1) {
        throw new Error(
          `Email (${args.patientEmail}) returned multiple users.`
        );
      }

      const patientData = await fetchPatientData(ids[0]);

      const userHasData = doesUserHaveAssessments({
        dhi: patientData.dhi,
        impact: patientData.impactTests,
        promis: patientData.promis
      });

      if (!userHasData) {
        throw new Error(
          `MISSING DATA. This user needs to complete at least one assessment.`
        );
      }

      history.push(generatePath(PatientRoutePattern, { patientId: ids[0] }));
    } catch (e) {
      console.log('Caught error', e);

      if (e instanceof Error) {
        state_setError(
          new Error(
            [
              e.message.trim(),
              `If you think this is an error please wait a few moments and try again, or contact ConcussionRX.`.trim()
            ].join(' ')
          )
        );
        return;
      }

      state_setError(
        new Error(`An uknown error occured. Got: '${e}' (${JSON.stringify(e)})`)
      );
    }
  }

  useEffect(() => {
    async function setup() {
      try {
        const data = await adminQueryPatientAssessmentStatus();
        const dataArr = JSON.parse(data.data) as APIPatientInfo[];

        const fixedArr = dataArr.map(_ => ({
          ..._,
          lastCompletedAssessment:
            _.lastCompletedAssessment !== null
              ? moment(_.lastCompletedAssessment)
              : null,
          recentAssessmentDate:
            _.lastCompletedAssessment !== null
              ? moment(_.recentAssessmentDate)
              : null,
          signUpDate: moment(_.signUpDate)
        }));
        setData({ data: fixedArr, isLoading: false });
      } catch (e) {
        console.error(
          `Failed to fetch ${adminQueryPatientAssessmentStatus.name} API`
        );
        console.error(e);
        state_setError(e as Error);
      }
    }
    setup();
  }, []);

  const filteredData = useMemo(() => {
    // Only show patients that have a completed assessment
    const _data = data.data.filter(_ => _.lastCompletedAssessment !== null);
    if (searchQuery === null) return _data;
    return _data.filter(_ =>
      JSON.stringify({
        displayName: _.displayName,
        email: _.email
        // patientId: _.patientId
      })
        .toLowerCase()
        .includes(searchQuery)
    );
  }, [data, searchQuery]);

  return (
    <ConfigProvider
      prefixCls="crx-clinician-home"
      theme={{
        components: {
          Table: {
            // rowHoverBg: '#A366FC'
          }
        }
      }}
    >
      <PrintableRootPage title="Patient Assessments" hideTitle>
        <Flex justify={'space-between'}>
          <Title>Patients</Title>
          <div>
            <CrxSearch
              onSearch={val => {
                if (val === '') {
                  setSearchQuery(null);
                  return;
                }
                setSearchQuery(val);
              }}
              placeholder={'Search patients'}
            />
          </div>
        </Flex>
        <Table
          loading={data.isLoading}
          dataSource={filteredData}
          sortDirections={['descend', 'ascend']}
          columns={[
            {
              title: 'Name',
              dataIndex: 'displayName',
              sorter: (a, b) => a.displayName.localeCompare(b.displayName)
            },
            {
              title: 'Email',
              dataIndex: 'email',
              sorter: (a, b) => a.email.localeCompare(b.email)
            },
            {
              title: 'Sign up date',
              dataIndex: 'signUpDate',
              render: (val: moment.Moment) => val.format('YYYY-MM-DD'),
              sorter: (a, b) =>
                Number(a.signUpDate?.unix() ?? 0) -
                Number(b.signUpDate?.unix() ?? 0)
            },
            {
              title: 'Last completed assessment',
              dataIndex: 'lastCompletedAssessment',
              render: (val: moment.Moment | null) =>
                val === null ? val : val.format('YYYY-MM-DD'),
              sorter: (a, b) =>
                Number(a.lastCompletedAssessment?.unix() ?? 0) -
                Number(b.lastCompletedAssessment?.unix() ?? 0)
            },
            {
              title: 'Recent assessment date',
              dataIndex: 'recentAssessmentDate',
              render: (val: moment.Moment | null) =>
                val === null ? val : val.format('YYYY-MM-DD'),
              sorter: (a, b) =>
                Number(a.recentAssessmentDate?.unix() ?? 0) -
                Number(b.recentAssessmentDate?.unix() ?? 0)
            }
          ]}
          onRow={record => ({
            onClick: async () => {
              setData(_ => ({ ..._, isLoading: true }));
              await onFinish({ patientEmail: record.email });
              setData(_ => ({ ..._, isLoading: false }));
            }
          })}
          rowHoverable={false}
        />

        {
          /**
           * Display an error message if the data fails to load
           */
          state_error && (
            <Text color={'red'}>
              An eror occured when loading data. {state_error?.message} If you
              think this is an error please wait a few moments and try again, or
              contact ConcussionRX.
            </Text>
          )
        }
      </PrintableRootPage>
    </ConfigProvider>
  );
}
