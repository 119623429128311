import { TestLaunchURLParameters } from 'api/impact/getTestLaunch';
import { Role } from 'features/auth/types';
import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { cloneDeepWith } from 'lodash';
import { unix } from 'moment';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const cloudFunctions = firebase.functions();
export const db = firebase.firestore();
export const storage = firebase.storage();
// Firebase emulator setup
if (process.env.REACT_APP_USE_FIREBASE_EMULATOR?.toLowerCase() === 'true') {
  console.log('----------------- USING FIREBASE EMULATOR ---------------');
  // db.settings({
  //   host: 'localhost:8088',
  //   ssl: false
  // });
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
  // auth.useEmulator('http://localhost:9099');
}
const _concussionrx_appVersion =
  process.env.NODE_ENV === 'test'
    ? 'test'
    : process.env.NODE_ENV === 'development'
    ? 'development'
    : process.env.REACT_APP_CONCUSSIONRX_APPVERSION;

if (!_concussionrx_appVersion)
  throw new Error(`'_concussionrx_appVersion' is not set`);

function httpsCallableWrapper<
  InType extends Record<string, any> | void,
  OutType
>(
  functionName: string
): (
  data?: InType
) => Promise<
  Omit<firebase.functions.HttpsCallableResult, 'data'> & { data: OutType }
> {
  return (data?: InType) =>
    cloudFunctions.httpsCallable(functionName)({
      ...data,
      _concussionrx_appVersion
    });
}

/** API: Adds clinician role to the current user, cloud function */
export const addClinicianRole: () => Promise<firebase.functions.HttpsCallableResult> =
  () => httpsCallableWrapper('addClinicianRole')();

/** API: Adds patient role to the current user, cloud function */
export const addPatientRole: () => Promise<firebase.functions.HttpsCallableResult> =
  () => httpsCallableWrapper('addPatientRole')();

export const recalculateSubSymptoms: (
  data: any
) => Promise<firebase.functions.HttpsCallableResult> = (data: any) =>
  httpsCallableWrapper('recalculateSubSymptoms')(data);

export const htmlToPDF = (
  html: string
): Promise<firebase.functions.HttpsCallableResult> =>
  httpsCallableWrapper('pdfExport')({ html });

export const adminQueryPatientAssessmentStatus = () =>
  httpsCallableWrapper<void, string>('admin-adminPatientAssessmentStatus')();

export const getImpactTestURL = (data: TestLaunchURLParameters) =>
  httpsCallableWrapper('getImPactTestURL')(data);

export const impactPassportIdExists = (passportId: string) =>
  httpsCallableWrapper('impactPassportIdExists')({ passportId });

export const initAssessment = (patientId: string) =>
  httpsCallableWrapper('assessment-initAssessment')({ patientId })
    // Convert the output to a usable structure
    .then(res => ({
      ...res,
      data: cloneDeepWith(res.data, value => {
        if (value === null) return;
        if (value.__date__ && value.unix) return unix(value.unix);
        return;
      })
    }));

export const verifyEmail = (redirectUrl: string) =>
  httpsCallableWrapper('auth-user-sendEmailVerification')({ redirectUrl });

export const initUser = (data: {
  role: Role;
  uid?: string;
  verifyEmailRedirectUrl: string;
}) => httpsCallableWrapper('auth-user-initUser')(data);

export const listPatients = (options: {
  clinicId: string;
  usersLimit?: number;
  usersLastDocId?: string;
  pendingUsersLastDocId?: string;
  pendingUsersLimit?: number;
}) => httpsCallableWrapper('clinic-list-patients')(options).then(parseAPIJSON);

export const listClinicians = (clinicId: string) =>
  httpsCallableWrapper('clinic-list-clinicians')({ clinicId }).then(
    parseAPIJSON
  );

export const addPatient = (data: {
  firstName: string;
  lastName: string;
  email: string;
  clinicId: string;
}) => httpsCallableWrapper('clinic-invite-patient')(data);

export const postSetPassword = () =>
  httpsCallableWrapper('auth-user-postSetPassword')();

export const getClinicBasicInfo = (clinicId: string) =>
  httpsCallableWrapper<{ clinicId: string }, { name: string }>(
    'clinic-get-basicInfo'
  )({ clinicId });

export const requestPasswordResetCode = () =>
  httpsCallableWrapper<any, string>('auth-user-requestPasswordResetCode')();

export const clinicActionAllowPatientAssessments = (patientId: string) =>
  httpsCallableWrapper<{ patientId: string }, string>(
    'clinic-action-patient-allowAssessments'
  )({
    patientId
  });

export const clinicActionDisablePatientAssessments = (patientId: string) =>
  httpsCallableWrapper<{ patientId: string }, string>(
    'clinic-action-patient-disableAssessments'
  )({
    patientId
  });

export const clinicActionResendInvitation = (invitationId: string) =>
  httpsCallableWrapper<{ invitationId: string }, 'Ok'>(
    'clinic-action-resendInvitation'
  )({ invitationId });
export const clinicActionEditPatientDetails = (
  patientId: string,
  firstName: string,
  lastName: string,
  email: string
) =>
  httpsCallableWrapper<
    { patientId: string; firstName: string; lastName: string; email: string },
    'Ok'
  >('clinic-action-patient-editDetails')({
    patientId,
    firstName,
    lastName,
    email
  }).then(parseAPIJSON);

export const invokeEnhancedTreatmentRecommendations = (patientId: string) =>
  httpsCallableWrapper('assessment-enhancedTreatmentRecommendations')({
    patientId
  });

/**
 * The Firebase onCall handler cannot stringify some arrays of documents returned by our APIs.
 * To fix this we stringify the results on the backend and parse them on the frontend
 */
function parseAPIJSON(data: { data: any }) {
  if (typeof data.data === 'string') {
    return { ...data, data: JSON.parse(data.data) };
  }

  return data;
}
