import { Flex, Skeleton } from 'antd';
import { createRepo } from 'api/firebase';
import {
  clinicActionAllowPatientAssessments,
  clinicActionDisablePatientAssessments,
  clinicActionEditPatientDetails,
  clinicActionResendInvitation,
  listPatients
} from 'api/firebase/firebase-api';
import { useAppSelector } from 'app/rootReducer';
import { PrintableRootPage } from 'components/page/page-2';
import { Clinician } from 'documents/clinician';
import { Collection } from 'documents/document';
import { Patient } from 'documents/patient';
import { User } from 'features/auth/authSlice';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { useEffect, useState } from 'react';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { ActionState } from './action-tag-component';
import { ActionTagEditDetails } from './action-tag-edit-details';
import UserList from './user-list-component';
export default function ClinicUserManagement() {
  const { user, isUserDataLoading } = useAppSelector(s => s.auth);
  const [clinicId, setClinicId] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    async function setup(user: User | null, isUserDataLoading: boolean) {
      if (isUserDataLoading) return;
      if (!user) throw new Error(`You must be logged in`);

      const clinicianRepo = createRepo<Clinician>(Collection.Clinicians);
      const clinicianInfo = await clinicianRepo.find(user.uid);
      if (!clinicianInfo)
        throw new Error(`Clinician (id=${user.uid}) not found`);
      clinicianInfo?.clinics[0];
      setClinicId(clinicianInfo?.clinics[0]);
    }
    setup(user, isUserDataLoading)
      .then(() => {
        setError(null);
      })
      .catch(error => {
        setError(resolveError(error));
      });
  }, [user, isUserDataLoading]);

  return (
    <PrintableRootPage title="User management">
      <Flex vertical gap={'middle'}>
        {clinicId && (
          <>
            <div>
              <UserList<Patient>
                typeUnit="Patient"
                api={({ clinicId, lastUserId, lastPendingUserId }) =>
                  listPatients({
                    clinicId,
                    usersLimit: 10,
                    pendingUsersLimit: 10,
                    ...(lastPendingUserId && {
                      pendingUsersLastDocId: lastPendingUserId
                    }),
                    ...(lastUserId && { usersLastDocId: lastUserId })
                  })
                }
                clinicId={clinicId}
                actions={[
                  {
                    name: p =>
                      p.raw.canStartAssessments
                        ? 'Activated'
                        : 'Activate Assessment',
                    onClick: async p => {
                      if (p.raw.id === undefined)
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      return clinicActionAllowPatientAssessments(p.raw.id);
                    },
                    shouldShow: p => !p.invitePending,
                    initialState: p =>
                      p.raw.canStartAssessments
                        ? ActionState.SUCCESS
                        : ActionState.NEUTRAL,
                    toolTip: p =>
                      p.raw.canStartAssessments
                        ? `This patient can start new assessments`
                        : `Click to allow this patient to start new assessments`
                  },
                  {
                    name: p =>
                      !p.raw.canStartAssessments
                        ? '[ONLY IN DEVELOPMENT] Disabled'
                        : '[ONLY IN DEVELOPMENT] Disable Assessment',
                    onClick: async p => {
                      if (p.raw.id === undefined)
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      return clinicActionDisablePatientAssessments(p.raw.id);
                    },
                    shouldShow: p =>
                      process.env.NODE_ENV === 'development' &&
                      !p.invitePending,
                    initialState: p =>
                      !p.raw.canStartAssessments
                        ? ActionState.SUCCESS
                        : ActionState.NEUTRAL,
                    toolTip: p =>
                      p.raw.canStartAssessments
                        ? `This patient cannot start new assessments`
                        : `Click to disable this patient from starting new assessments`
                  },
                  {
                    name: 'Resend invitation',
                    shouldShow: p => p.invitePending,
                    initialState: () => ActionState.NEUTRAL,
                    onClick: p => {
                      if (!p.raw.id)
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      return clinicActionResendInvitation(p.raw.id);
                    }
                  },
                  {
                    name: 'Edit details',
                    initialState: () => ActionState.NEUTRAL,
                    render: ActionTagEditDetails,
                    onClick: p => {
                      if (!p.raw.id) {
                        throw new Error(
                          'ID is undefined. Please try reloading the page'
                        );
                      }

                      return clinicActionEditPatientDetails(
                        p.raw.id,
                        p.firstName,
                        p.lastName,
                        p.email
                      );
                    },
                    onSuccess: {
                      blockOnDone: false,
                      timeout: 1000
                    }
                  }
                ]}
              />
            </div>

            {/* <div>
          <Title>Clinicians</Title>
          <UserList api={listClinicians} clinicId={clinicId} />
        </div> */}
          </>
        )}
        {!clinicId && !error && <Skeleton active />}
        {error && (
          <ErrorDisplay
            title="Something went wrong when loading clinician information"
            description={`Please report this error to contact@concussionrx.com`}
            error={error}
          />
        )}
      </Flex>
    </PrintableRootPage>
  );
}
